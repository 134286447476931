import axios from "axios";
import md5 from "md5";

const baseUrl = window.env.API_URL;
class analyticsApiClient {
  constructor() {
    this.init();
  }

  init() {
    let options = {
      baseURL: baseUrl,
    };

    let token = JSON.parse(localStorage.getItem("authToken"));
    if (token) {
      options.headers = {
        Authorization: `Bearer ${token}`,
      };
    }
    this.client = axios.create(options);
    this.client.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          window.location.href = "/login";
        }

        return Promise.reject(error);
      }
    );
  }

  async auth(username, password) {
    try {
      let response = await axios
        .create({
          baseURL: baseUrl,
        })
        .post("api/users/token", {
          username,
          password: md5(password),
        });

      return response.data;
    } catch (ex) {
      return "";
    }
  }

  async get_profile() {
    let response = await this.client.get("api/users/profile");
    return response.data;
  }

  async get_report_embedding_data() {
    let response = await this.client.get("api/reports");
    return response.data;
  }

  async get_tenants() {
    let response = await this.client.get("api/tenants");
    return response.data;
  }

  async onboard_tenant(data) {
    let response = await this.client.post("api/tenants/create", data);
    return response.data;
  }

  async delete_tenant(id) {
    let response = await this.client.delete(`api/tenants/delete/${id}`);
    return response.data;
  }

  async get_tenant_report_embedding_data(id) {
    let response = await this.client.get(`api/tenants/reports/${id}`);
    return response.data;
  }

  async export_report(data) {
    let response = await this.client.post("api/reports/exportfile", data, {
      responseType: "blob",
    });

    const header = response.headers.get("Content-Disposition");
    const parts = header.split(";");
    let filename = parts[1].split("=")[1].replaceAll('"', "");

    var url = window.URL.createObjectURL(response.data);
    var tempLink = document.createElement("a");
    tempLink.href = url;
    tempLink.download = filename;
    tempLink.click();
    tempLink.remove();

    return;
  }

  async refresh_data(id) {
    if (id) {
      let response = await this.client.post(`api/tenants/extractdata?id=${id}`);
      return response.data;
    } else {
      let response = await this.client.post(`api/tenants/extractdata`);
      return response.data;
    }
  }

  async refresh_report(id) {
    if (id) {
      let response = await this.client.post(
        `api/tenants/refreshreport?id=${id}`
      );
      return response.data;
    } else {
      let response = await this.client.post(`api/tenants/refreshreport`);
      return response.data;
    }
  }

  async upload(file) {
    let formData = new FormData();

    formData.append("file", file);

    return await this.client.post("api/tenants/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  async get_files() {
    let response = await this.client.get("api/tenants/files");
    return response.data;
  }
}

export default new analyticsApiClient();
