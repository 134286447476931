import Person from "@mui/icons-material/Person";
import Container from "@mui/material/Container";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import { useAuth } from "hooks/useAuth";
import { useState, useEffect, useRef } from "react";
import analyticsApiClient from "analytics-api-client";
import dayjs from "dayjs";
import { Button, Input } from "@mui/material";

export const SettingsPage = () => {
  const { user } = useAuth();
  const [selectedFiles, setSelectedFiles] = useState(undefined);
  const [message, setMessage] = useState([]);
  const [fileInfos, setFileInfos] = useState([]);
  const isInitialised = useRef(false);

  useEffect(() => {
    if (!isInitialised.current) {
      isInitialised.current = true;
      loadFiles();
    }
  }, []);

  const loadFiles = async () => {
    let data = await analyticsApiClient.get_files();
    setFileInfos(data);
  };

  const upload = async (idx, file) => {
    await analyticsApiClient.upload(file);
    setMessage((prevMessage) => [
      ...prevMessage,
      "Uploaded the file successfully: " + file.name,
    ]);
  };

  const uploadFiles = async () => {
    const files = Array.from(selectedFiles);
    Promise.all(files.map((file, i) => upload(i, file))).then(async () => {
      await loadFiles();
      //setMessage([]);
      setSelectedFiles(undefined);
    });
  };

  return (
    <Container maxWidth="xl">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          Settings
        </Typography>

        {/* <Button
          sx={{
            ml: 2,
          }}
          variant="contained"
          color="secondary"
          onClick={(e) => refreshAll()}
        >
          Refresh all
        </Button> */}
      </Box>

      <Typography>
        Update SQL queries used to generate the databases / update PBI template
      </Typography>
      <Box
        sx={{
          marginTop: 2,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Input
          type="file"
          inputProps={{ multiple: true }}
          onChange={(e) => setSelectedFiles(e.target.files)}
        />
        <Button
          sx={{
            ml: 2,
          }}
          color="primary"
          variant="contained"
          disabled={!selectedFiles}
          onClick={uploadFiles}
        >
          Upload
        </Button>
      </Box>
      {message.length > 0 && (
        <Alert>
          <ul>
            {message.map((item, i) => {
              return <li key={i}>{item}</li>;
            })}
          </ul>
        </Alert>
      )}
      {fileInfos && (
        <TableContainer
          component={Paper}
          sx={{
            marginTop: 4,
          }}
        >
          <Table sx={{ minWidth: 650 }} size="small">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Size</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fileInfos.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell>{dayjs(row.date).format("DD/MM HH:mm")}</TableCell>
                  <TableCell>{row.size}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Container>
  );
};
