import React, { useEffect, useState, useRef } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import Tooltip from "@mui/material/Tooltip";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import analyticsApiClient from "analytics-api-client";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import PreviewIcon from "@mui/icons-material/Preview";
import RefreshIcon from "@mui/icons-material/Refresh";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { TextField, Button, Link } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { green, blue, red } from "@mui/material/colors";
import { PowerBIEmbed } from "powerbi-client-react";
import ConfirmBox from "components/ConfirmBox";
import * as models from "powerbi-models";
import dayjs from "dayjs";
import { useInterval } from "react-use";
import CloudSyncIcon from "@mui/icons-material/CloudSync";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import InfoIcon from "@mui/icons-material/Info";
import Alert from "@mui/material/Alert";
//import { Form, Formik } from "formik";
// import { MultipleFileUploadField } from "components/upload/MultipleFileUploadField";
// import { Grid } from "@mui/material";
// import { array, object, string } from "yup";

export const TenantsPage = () => {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [createFormError, setCreateFormError] = useState(false);
  const [createFormVisible, setCreateFormVisible] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [confirmRefreshDbOpen, setConfirmRefreshDbOpen] = useState(false);
  const [confirmRefreshTemplateOpen, setConfirmRefreshTemplateOpen] =
    useState(false);
  const [deleteData, setDeleteData] = useState([]);
  const [selectedTenant, setSelectedTenant] = useState(false);

  const isInitialised = useRef(false);

  const loadTenants = async () => {
    let data = await analyticsApiClient.get_tenants();
    setData(data);
  };

  useEffect(() => {
    if (!isInitialised.current) {
      isInitialised.current = true;
      loadTenants();
    }
  }, []);

  const showTenantInfo = async (row) => {
    setSelectedTenant(row);
    setOpen(true);
  };

  const deleteTenant = async () => {
    let response = await analyticsApiClient.delete_tenant(deleteData.tenantId);
    setConfirmDeleteOpen(false);
    loadTenants();
  };

  const refreshData = async (row) => {
    let response = await analyticsApiClient.refresh_data(row.tenantId);
    loadTenants();
  };

  const refreshReport = async (row) => {
    let response = await analyticsApiClient.refresh_report(row.tenantId);
    loadTenants();
  };

  const refreshAll = async () => {
    let response = await analyticsApiClient.refresh_data();
    setConfirmRefreshDbOpen(false);
    loadTenants();
  };

  const refreshReports = async () => {
    let response = await analyticsApiClient.refresh_report();
    setConfirmRefreshTemplateOpen(false);
    loadTenants();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    let data = {
      clientId: formData.get("clientId"),
      name: formData.get("name"),
      databaseServer: formData.get("databaseServer"),
      databaseName: formData.get("databaseName"),
      databaseUserName: formData.get("databaseUserName"),
      databaseUserPassword: formData.get("databaseUserPassword"),
      languageId: formData.get("languageId"),
    };
    console.log(data);

    if (!loading) {
      setLoading(true);
      let response = await analyticsApiClient.onboard_tenant(data);
      setLoading(false);
      if (response.error) {
        setCreateFormError(response.description);
      } else {
        console.log(response);

        setCreateFormVisible(false);
        loadTenants();
      }
    }
  };

  const getStatus = (statusId, error) => {
    switch (statusId) {
      case 1:
        return (
          <CheckCircleIcon
            sx={{
              color: green[900],
            }}
          />
        );
      case 2:
        return (
          <CircularProgress
            size={24}
            sx={{
              color: blue[500],
            }}
          />
        );
      case 3:
        return (
          <Tooltip title={error}>
            <ErrorIcon
              sx={{
                color: red[900],
              }}
            />
          </Tooltip>
        );
    }
    return "";
  };

  useInterval(() => {
    loadTenants();
  }, 15000);

  return (
    <Container maxWidth="xl">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        {/* <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
          <Person />
        </Avatar> */}
        <Typography component="h1" variant="h5">
          Tenants
        </Typography>
        <Button
          sx={{
            ml: 2,
          }}
          variant="contained"
          color="primary"
          onClick={(e) => {
            setCreateFormError("");
            setCreateFormVisible(true);
          }}
        >
          Create new
        </Button>
        <Button
          sx={{
            ml: 2,
          }}
          variant="contained"
          color="secondary"
          onClick={(e) => {
            setConfirmRefreshDbOpen(true);
          }}
        >
          Refresh all - database
        </Button>

        <Button
          sx={{
            ml: 2,
          }}
          variant="contained"
          color="secondary"
          onClick={(e) => {
            setConfirmRefreshTemplateOpen(true);
          }}
        >
          Refresh all - pbi template
        </Button>
      </Box>

      {/* <Box
        sx={{
          marginTop: 4,
          marginBottom: 4,
        }}
      >
        <Formik
          initialValues={{ files: [] }}
          validationSchema={object({
            files: array(
              object({
                url: string().required(),
              })
            ),
          })}
          onSubmit={(values) => {
            console.log("values", values);
            return new Promise((res) => setTimeout(res, 2000));
          }}
        >
          {({ values, errors, isValid, isSubmitting }) => (
            <Form>
              <Grid container spacing={2} direction="column">
                <MultipleFileUploadField name="files" />

                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={!isValid || isSubmitting}
                    type="submit"
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>

              <pre>{JSON.stringify({ values, errors }, null, 4)}</pre>
            </Form>
          )}
        </Formik>
      </Box> */}

      {data && (
        <TableContainer
          component={Paper}
          sx={{
            marginTop: 8,
          }}
        >
          <Table sx={{ minWidth: 650 }} size="small">
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>C Id</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Pbi tpl date</TableCell>
                <TableCell>Db ref</TableCell>
                <TableCell>Ds ref</TableCell>
                <TableCell>Ds ref st</TableCell>
                <TableCell>Time(s)</TableCell>
                <TableCell>Lang</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => (
                <TableRow
                  key={row.tenantId}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.tenantId}
                  </TableCell>
                  <TableCell>{row.clientId}</TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>
                    {row.reportRefreshDate &&
                      dayjs(row.reportRefreshDate).format("DD/MM")}
                  </TableCell>
                  <TableCell>
                    {row.databaseRefreshDateTime &&
                      dayjs(row.databaseRefreshDateTime).format("DD/MM HH:mm")}
                  </TableCell>
                  <TableCell>
                    {row.datasetRefreshDateTime &&
                      dayjs(row.datasetRefreshDateTime).format("DD/MM HH:mm")}
                  </TableCell>
                  <TableCell>{row.datasetRefreshStatus}</TableCell>
                  <TableCell>{row.databaseRefreshDuration}</TableCell>
                  <TableCell>{row.languageId}</TableCell>
                  <TableCell>
                    {getStatus(row.status, row.datasetRefreshError)}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="showinfo"
                      onClick={(e) => showTenantInfo(row)}
                    >
                      <Tooltip title="Show info">
                        <InfoIcon />
                      </Tooltip>
                    </IconButton>
                    <IconButton
                      aria-label="openurl"
                      onClick={(e) => {
                        window.open(row.workspaceUrl, "_blank", "noreferrer");
                      }}
                    >
                      <Tooltip title="Open PBI workspace">
                        <OpenInNewIcon />
                      </Tooltip>
                    </IconButton>
                    <IconButton
                      aria-label="preview"
                      //onClick={(e) => previewTenant(row)}
                      onClick={(e) => {
                        window.open(
                          `/dashboard/reports?tid=${
                            row.tenantId
                          }&lid=${row.languageId
                            .replace("fr-BE", "fr-FR")
                            .replace("nl-BE", "nl-NL")}`,
                          "_blank",
                          "noreferrer"
                        );
                      }}
                    >
                      <Tooltip title="View PBI report">
                        <PreviewIcon />
                      </Tooltip>
                    </IconButton>
                    <IconButton
                      aria-label="refreshdata"
                      onClick={(e) => refreshData(row)}
                    >
                      <Tooltip title="Refresh client data extract">
                        <RefreshIcon />
                      </Tooltip>
                    </IconButton>

                    <IconButton
                      aria-label="refreshreport"
                      onClick={(e) => refreshReport(row)}
                    >
                      <Tooltip title="Replace PBI report with default">
                        <CloudSyncIcon />
                      </Tooltip>
                    </IconButton>
                    <IconButton
                      aria-label="delete"
                      onClick={(e) => {
                        setConfirmDeleteOpen(true);
                        setDeleteData(row);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Dialog
        onClose={(e) => setOpen(false)}
        open={open}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Tenant info</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell>
                    <strong>Name</strong>
                  </TableCell>
                  <TableCell>{selectedTenant.name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <strong>Database server</strong>
                  </TableCell>
                  <TableCell>{selectedTenant.databaseServer}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <strong>Database name</strong>
                  </TableCell>
                  <TableCell>{selectedTenant.databaseName}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <strong>Database user</strong>
                  </TableCell>
                  <TableCell>{selectedTenant.databaseUserName}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <strong>Database password</strong>
                  </TableCell>
                  <TableCell>{selectedTenant.databaseUserPassword}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog
        onClose={(e) => setCreateFormVisible(false)}
        open={createFormVisible}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>New customer</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{
                marginTop: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {createFormError && (
                <Alert severity="error">{createFormError}</Alert>
              )}
              <TextField
                required
                label="Client ID"
                name="clientId"
                margin="dense"
                fullWidth
              />

              <TextField
                label="Client name"
                name="name"
                margin="dense"
                defaultValue=""
                fullWidth
              />

              <TextField
                required
                label="Language ID"
                name="languageId"
                margin="dense"
                defaultValue="fr-BE"
                fullWidth
              />

              {/* <TextField
                required
                label="Database Server"
                name="databaseServer"
                margin="dense"
                defaultValue="pgs.whise.eu"
                fullWidth
              />

              <TextField
                required
                label="Database Name"
                name="databaseName"
                margin="dense"
                defaultValue="whise_"
                fullWidth
              />

              <TextField
                required
                label="Database User Name"
                name="databaseUserName"
                margin="dense"
                defaultValue="u_"
                fullWidth
              />

              <TextField
                required
                label="Database User Password"
                type="password"
                name="databaseUserPassword"
                margin="dense"
                fullWidth
              /> */}

              <Box sx={{ m: 1, position: "relative" }}>
                <Button
                  sx={{ mt: 3, mb: 2 }}
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={loading}
                >
                  Create
                </Button>
                {loading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: green[500],
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                )}
              </Box>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <ConfirmBox
        open={confirmDeleteOpen}
        closeDialog={() => setConfirmDeleteOpen(false)}
        title={`Delete ${deleteData?.name}`}
        message={`Are your sure you want to delete ${deleteData?.name}?`}
        confirmFunction={deleteTenant}
      />

      <ConfirmBox
        open={confirmRefreshDbOpen}
        closeDialog={() => setConfirmRefreshDbOpen(false)}
        title={`Refresh all - database`}
        message={`This action will run database refresh for all clients. Are you sure you want to do that?`}
        confirmFunction={refreshAll}
      />

      <ConfirmBox
        open={confirmRefreshTemplateOpen}
        closeDialog={() => setConfirmRefreshTemplateOpen(false)}
        title={`Refresh all - PBI template`}
        message={`This action will replace PBI template for all clients. Are you sure you want to do that?`}
        confirmFunction={refreshReports}
      />
    </Container>
  );
};
