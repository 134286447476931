import React, { useEffect, useState, useRef } from "react";
import { PowerBIEmbed } from "powerbi-client-react";
import analyticsApiClient from "analytics-api-client";
import * as models from "powerbi-models";
import { Box, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";

import ReportToolbar from "./ReportToolbar";

export const ReportsPage = () => {
  const isInitialised = useRef(false);
  let [searchParams, setSearchParams] = useSearchParams();
  const [report, setReport] = useState();
  const [embedType, setEmbedType] = useState(null);
  const [reportType, setReportType] = useState(null);
  const [viewMode, setViewMode] = useState("FitToWidth");
  const [editMode, setEditMode] = useState(false);
  const [showNavigation, setShowNavigation] = useState(true);
  const [showFiltersPane, setShowFiltersPane] = useState(true);
  const [showBookmarksPane, setShowBookmarksPane] = useState(false);
  const [embedToken, setEmbedToken] = useState(null);
  const [embedTokenExpiration, setEmbedTokenExpiration] = useState(null);
  const [reportPath, setReportPath] = useState("");

  const [sampleReportConfig, setReportConfig] = useState({
    type: "report",
    embedUrl: undefined,
    tokenType: models.TokenType.Embed,
    accessToken: undefined,
    viewMode: editMode ? models.ViewMode.Edit : models.ViewMode.View,
    permissions: models.Permissions.All,
    settings: {
      layoutType: models.LayoutType.Custom,
      customLayout: { displayOption: models.DisplayOption.ActualSize },
      localeSettings: {
        language: searchParams.get("lid"),
        formatLocale: searchParams.get("lid"),
      },
      bars: {
        actionBar: { visible: false },
      },
      panes: {
        pageNavigation: {
          visible: true,
          position: models.PageNavigationPosition.Left,
        },
        filters: { visible: true, expanded: true },
      },
    },
  });

  const eventHandlersMap = new Map([
    ["loaded", function () {}],
    ["rendered", function () {}],
    [
      "error",
      function (event) {
        if (event) {
          console.error(event.detail);
        }
      },
    ],
  ]);

  const loadReport = async () => {
    var tid = searchParams.get("tid");
    let embeddingData = tid
      ? await analyticsApiClient.get_tenant_report_embedding_data(tid)
      : await analyticsApiClient.get_report_embedding_data();
    if (!embeddingData) {
      alert("no reports found");
    } else {
      //console.log(embeddingData);

      let embedUrl = embeddingData.reports[0].embedUrl;
      let embedToken = embeddingData.embedToken;

      setReportPath(embeddingData.reports[0].name);

      let config = {
        ...sampleReportConfig,
        embedUrl: embedUrl,
        accessToken: embedToken,
      };

      setReportConfig(config);
    }
  };

  useEffect(() => {
    if (!isInitialised.current) {
      isInitialised.current = true;
      loadReport();
    }
  }, []);

  return (
    <Box sx={{ display: "grid", gridAutoFlow: "row", width: 1 }}>
      <Box
        sx={{
          width: 1,
          background: "#0662b0",
          p: 0,
          m: 0,
          display: "flex",
          maxHeight: "36px",
        }}
      >
        <Typography
          variant="h2"
          sx={{
            pl: "12px",
            pt: "8px",
            fontSize: "16px",
            color: "white",
            minHeight: "36px",
            fontFamily: "arial",
          }}
        >
          {reportPath}
        </Typography>
      </Box>

      <ReportToolbar
        report={report}
        editMode={editMode}
        setEditMode={setEditMode}
        showNavigation={showNavigation}
        setShowNavigation={setShowNavigation}
        showFiltersPane={showFiltersPane}
        setShowFiltersPane={setShowFiltersPane}
        viewMode={viewMode}
        setViewMode={setViewMode}
        showBookmarksPane={showBookmarksPane}
        setShowBookmarksPane={setShowBookmarksPane}
        setEmbedToken={setEmbedToken}
        setEmbedTokenExpiration={setEmbedTokenExpiration}
      />

      <PowerBIEmbed
        embedConfig={sampleReportConfig}
        eventHandlers={eventHandlersMap}
        cssClassName={"report-style-class"}
        getEmbeddedComponent={(embedObject) => {
          setReport(embedObject);
        }}
      />
    </Box>
  );
};
