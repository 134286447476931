import { Routes, Route } from "react-router-dom";
import { LoginPage } from "pages/Login";
import { HomePage } from "pages/Home";
import { SettingsPage } from "pages/Settings";
import { TenantsPage } from "pages/Tenants";
import { ReportsPage } from "pages/Reports";
import { ProtectedLayout } from "components/ProtectedLayout";
import { HomeLayout } from "components/HomeLayout";
import "styles.css";

export default function App() {
  return (
    <Routes>
      <Route element={<HomeLayout />}>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<LoginPage />} />
      </Route>

      <Route path="/dashboard" element={<ProtectedLayout />}>
        <Route path="settings" element={<SettingsPage />} />
        <Route path="tenants" element={<TenantsPage />} />
        <Route path="reports" element={<ReportsPage />} />
      </Route>
    </Routes>
  );
}
