import { Navigate, useOutlet } from "react-router-dom";
import { useAuth } from "hooks/useAuth";
import { AppBar } from "components/AppBar";

export const HomeLayout = () => {
  const { user } = useAuth();
  const outlet = useOutlet();

  if (user) {
    if (user.clientId != 1) {
      return <Navigate to="/dashboard/reports" replace />;
    } else {
      return <Navigate to="/dashboard/tenants" replace />;
    }
  }

  return (
    <div>
      <AppBar
        pages={[
          { label: "Home", path: "/", visible: true },
          { label: "Login", path: "/login", visible: true },
        ]}
      />
      {outlet}
    </div>
  );
};
