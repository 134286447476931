import { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";
import analyticsApiClient from "analytics-api-client";
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage("user", null);
  const [token, setToken] = useLocalStorage("authToken", null);

  const navigate = useNavigate();

  const login = async (data) => {
    let authData = await analyticsApiClient.auth(data.username, data.password);
    if (authData) {
      setToken(authData.token);
      analyticsApiClient.init();
      let profileData = await analyticsApiClient.get_profile();
      setUser(profileData);

      if (profileData.clientId != 1) {
        navigate("/dashboard/reports", { replace: true });
      } else {
        navigate("/dashboard/tenants", { replace: true });
      }
    }
  };

  const logout = () => {
    setUser(null);
    navigate("/", { replace: true });
  };

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
    }),
    [user]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
