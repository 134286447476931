import Container from "@mui/material/Container";
import Box from "@mui/material/Box";

export const HomePage = () => {
  return (
    <Container maxWidth="xl">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        Welcome to Whise Analytics - the ultimate business intelligence tool
        that provides powerful insights and analytics to drive informed
        decision-making. With Whise Analytics, you can easily connect to various
        data sources and gather meaningful insights into your business's
        performance. Whise Analytics's intuitive interface allows you to analyze
        and visualize data, and collaborate with your team to make data-driven
        decisions. Our state-of-the-art features include data modeling,
        reporting, and dashboard creation, ensuring you get a comprehensive
        understanding of your business operations. <br />
        <br />
        <br />
        With Whise Analytics, you can track your KPIs, sales, marketing, and
        finance data, and create custom reports and dashboards to visualize your
        data in real-time. Whise Analytics provides secure access and data
        privacy to ensure your business data remains confidential. Our platform
        is user-friendly, and with our simple drag-and-drop features, you can
        create reports and dashboards without the need for coding expertise.
        <br />
        <br />
      </Box>
    </Container>
  );
};
